.featureToast_wrapper {
  height: 100px;
}
.featureToast {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.featureToast .step {
  position: relative;
  border: 1px solid #4fc8e9;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.featureToast .step .step_text {
  position: absolute;
  top: 40px;
  left: 0;
  width: 150px;
}
.featureToast .step .step_text .heading {
  font-size: 14px;
  font-weight: bold;
}
.featureToast .step .step_text .body {
  font-size: 12px;
}
.featureToast .step svg {
  margin-top: 2px;
}

.featureToast .step_connect {
  width: 22%;
  border-top: 1px dotted;
  position: relative;
}
.featureToast .step_connect svg {
  position: absolute;
  top: -5px;
}

@media (max-width: 426px) {
  .featureToast .step .step_text {
    display: none;
  }
  .featureToast .step_connect {
    width: 14%;
  }
}
