.featured_vehicles .heading {
  font-size: 1.5rem;
  font-weight: bold;
}
.chooseVehicle_pagination {
  margin: 4rem 0;
}
.chooseVehicle_pagination .MuiPagination-ul {
  justify-content: center;
}
.chooseVehicle_pagination .MuiPagination-ul li:first-child button,
.chooseVehicle_pagination .MuiPagination-ul li:last-child button {
  border: 1px solid #4fc8e9;
  /* border-radius: 100%; */
}
.chooseVehicle_pagination .MuiPagination-ul li:first-child button svg,
.chooseVehicle_pagination .MuiPagination-ul li:last-child button svg {
  fill: #4fc8e9;
}

.chooseVehicle_pagination .MuiPagination-ul li button {
  color: white;
}
.chooseVehicle_pagination .MuiPagination-ul li button[aria-current='true'] {
  background: transparent;
  color: #4fc8e9;
}
