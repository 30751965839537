.nav_bar {
  background: #252b48;
  padding: 0 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white !important;
  box-shadow: 0px 2px 6px 2px #14182b;
}
.logo img {
  max-width: 100px;
}

.nav_buttons {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  font-size: 12px;
}
.nav_buttons a {
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: white;
}
.nav_buttons a:hover {
  opacity: 0.8;
}
.nav_buttons_search {
  background: #1c2038;
  height: 22px;
  border-radius: 5px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}

.nav_bar .navbar-toggler {
  border: none;
  box-shadow: none;
}
@media (max-width: 992px) {
  .nav_buttons {
    align-items: flex-start;
    padding: 0 3%;
  }
}

@media (max-width: 426px) {
  .nav_bar {
    padding: 0 0%;
  }
  .nav_buttons_search {
    margin-bottom: 1rem;
  }
}
