.cards {
  width: 33.33%;
  min-height: 250px;
  background: #252b48;
  border-radius: 16px;
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
.cards .cards_img {
  background: #1c2038;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
}
.cards .cards_img img {
  transform: rotate(-45deg);
  max-width: 25px;
}
.cards .cards_heading {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.cards .cards_body {
  font-size: 12px;
  text-align: center;
}

@media (max-width: 426px) {
  .cards {
    width: auto;
  }
}
