.timingsCard_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10rem;
  position: relative;
  z-index: 100;
}
.timingsCard {
  width: 77%;
  color: white;
  background: #252b48;
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.26);
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
}
.timingsCard .fieldsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.timingsCard .destination_select .MuiFormControl-root {
  margin: 0;
  margin-top: 10px;
}
.timingsCard .destination_select .MuiInputLabel-root {
  font-size: 12px;
  width: 140px;
  color: white;
}
.timingsCard .destination_select .MuiInputBase-root {
  font-size: 11px;
  width: 280px;
  border: 1px solid white;
  color: white;
}
.timingsCard .MuiMenu-paper {
  background-color: #252b48 !important;
  color: white !important;
  font-size: 14px !important;
  width: 280px;
  /* left: 192px !important; */
}
.airport_name_menu_item {
  font-size: 11px !important;
}

.timingsCard .destination {
  width: 280px !important;
}
.timingsCard .fieldsWrapper .date_time {
  border: 1px solid white;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
}

.timingsCard .time_date_picker:first-child {
  border-right: 1px solid white;
}
.timingsCard .time_date_picker {
  width: 140px;
}
.timingsCard .MuiOutlinedInput-notchedOutline,
.timingsCard .MuiOutlinedInput-notchedOutline:hover {
  /* border: 1px solid #ffffff !important; */
  border: none !important;
  color: white;
}
.timingsCard .time_date_picker label,
.timingsCard .time_date_picker .MuiOutlinedInput-root,
.timingsCard svg {
  color: white;
  font-size: 12px;
}

.timingsCard_submit {
  background: #4fc8e9;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 5px 2.5rem;
}
.timepicker_dropdown .ant-picker-panel-container {
  background: #252b48;
}
.timepicker .ant-picker-input input {
  color: white;
}
.timepicker .ant-picker-input input::placeholder {
  color: white;
}
.timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-cell-inner {
  color: white !important;
}
.timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-cell-inner:hover {
  background: #3b446c !important;
}
.timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #3b446c !important;
}
.timepicker_dropdown
  .ant-picker-panel-container
  .ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #3b446c;
}
.timepicker_dropdown ant-picker-footer {
  border-top: 1px solid #3b446c;
}
.timepicker_dropdown ant-picker-footer ant-picker-ok button {
  color: white;
  border-color: #15171f;
  background: #3b446c;
  border-radius: 3px;
}

@media (max-width: 426px) {
  .timingsCard_wrapper {
    margin-top: -6rem;
    padding: 0 3%;
  }
  .timingsCard {
    width: 100%;
  }
  .timingsCard .fieldsWrapper {
    flex-direction: column;
  }
  .timingsCard_submit {
    width: 100%;
  }
}
