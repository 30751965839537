.featuredPage {
  padding: 2rem 3%;
}
.Mui-focused .featuredPage_sortOptions .MuiFormControl-root {
  margin: 0;
  margin-top: 10px;
}
.featuredPage_sortOptions .MuiInputLabel-root,
.featuredPage_sortOptions .MuiInputLabel-root .Mui-focused {
  font-size: 12px;
  color: white !important;
}

.featuredPage_sortOptions .MuiInputBase-root {
  font-size: 11px;
  /* width: 280px; */
  border: 1px solid white;
  color: white;
}
.featuredPage_sortOptions .MuiOutlinedInput-notchedOutline,
.featuredPage_sortOptions .MuiOutlinedInput-notchedOutline:hover {
  /* border: 1px solid #ffffff !important; */
  border: none !important;
  color: white;
}
.featuredPage_sortOptions svg {
  color: white;
  font-size: 14px;
}
.MuiMenu-paper {
  background-color: #252b48 !important;
  color: white !important;
  font-size: 14px !important;
  /* width: 280px; */
  /* left: 192px !important; */
}
.airport_name_menu_item {
  font-size: 11px !important;
}
.featuredPage_sortOptions {
  margin: 3rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sortOptions_no_of_passenger .MuiFormControl-root {
  width: 180px;
}

.featuredPage_sortOptions .attribute_wrapper {
  display: flex;
  justify-content: start;
  gap: 1rem;
}
.featuredPage_sortOptions .sortOptions_mileage {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  font-weight: bold;
  font-size: 14px;
}

.featured_card_wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

@media (max-width: 426px) {
  .featuredPage_sortOptions {
    display: none;
  }
}
